import React from 'react'

import Layout from "../components/layout"
import SEO from "../components/seo"

import contactUsImg from "../images/contact-us-img.jpg"

const ContactUs = () => (
    <Layout>
        <SEO title="Contact Us" />
        <div className="contact-us container mx-auto">
            <h1 className="text-center contact-us__title font-title mt-20 mb-10">Contact Us</h1>
            <div className="flex flex-wrap mt-20 justify-center text-center">
                <form action="" className="contact-us__form w-full mb-10 md:mb-0 md:w-1/2 xl:mb-0 xl:w-1/2  px-10">
                    <p className="mb-5">Do you have some questions or suggestions?</p>
                    <div className="mb-4">
                        <input className="bg-white focus:outline-none focus:shadow-outline border rounded py-2 px-4 block w-full appearance-none leading-normal mr-2" type="text" placeholder="Name" />
                    </div>
                    <div className="mb-4">
                        <input className="bg-white focus:outline-none focus:shadow-outline border rounded py-2 px-4 block w-full appearance-none leading-normal mr-2" type="email" placeholder="Email" />
                    </div>
                    <div className="mb-4">
                        <input className="bg-white focus:outline-none focus:shadow-outline border rounded py-2 px-4 block w-full appearance-none leading-normal mr-2" type="text" placeholder="Phone" />
                    </div>
                    <div className="mb-4">
                        <textarea cols="30" rows="10" className="border w-full"></textarea>
                    </div>
                    <div className="mb-4 mx-auto">
                        <input className="italic uppercase bg-black focus:outline-none focus:shadow-outline border py-4 px-10 block appearance-none leading-normal mx-auto" type="submit" value="Submit" />
                    </div>
                </form>
            </div>
        </div>
    </Layout>
)

export default ContactUs